import { lazy, Suspense } from "react";
import { Loading } from "./layout/utils/AppLoading";

const MainContainer = lazy(() => import("./containers/MainContainer"));

const App = () => {
  return (
    <Suspense fallback={<Loading centered />}>
      <MainContainer />
    </Suspense>
  );
};

export default App;
