import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* REACTORS */
export const getReactor = createSelector(
  (state) => state.reactor,
  (reactor) => reactor
);
export const getReactors = createSelector(
  (state) => state.reactors,
  (reactors) => reactors
);
/* PRODUCTIONORDER */
export const getProductionOrder = createSelector(
  (state) => state.productionOrder,
  (productionOrder) => productionOrder
);
