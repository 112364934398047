import { handleActions } from "redux-actions";
import {
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  SET_REACTOR_LIST,
  SET_REACTOR,
  SET_PRODUCTION_ORDER,
} from "./../../constants";

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const reactors = handleActions(
  {
    [SET_REACTOR_LIST]: (state, action) => action.payload,
  },
  null
);

export const reactor = handleActions(
  {
    [SET_REACTOR]: (state, action) => action.payload,
  },
  null
);

export const productionOrder = handleActions(
  {
    [SET_PRODUCTION_ORDER]: (state, action) => action.payload,
  },
  null
);
