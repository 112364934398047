import { combineReducers } from "redux";
import {
  notifications,
  app,
  reactor,
  reactors,
  productionOrder,
} from "./Reducers";

export default combineReducers({
  app,
  notifications,
  reactor,
  reactors,
  productionOrder,
});
