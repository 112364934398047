/* BASIC APP CONSTANTS */
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
/* <---- BASIC APP CONSTANTS ----> */

export const SET_REACTOR_LIST = "SET_REACTOR_LIST";
export const SET_REACTOR = "SET_REACTOR";
export const SET_PRODUCTION_ORDER = "SET_PRODUCTION_ORDER";
